<template>
	<Projects></Projects>
</template>

<script>
import Projects from "@/components/shared/project/Index.vue";

export default {
	name: "ProjectsPage",

	components: {
		Projects,
	},
};
</script>

<style></style>
