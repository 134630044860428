<template>
	<v-container fluid class="wr_grey_1">
		<v-card align="center" flat class="wr_grey_1" height="100%">
			<v-card-text align="start" :style="{ height: '10%' }">
				<!-- HEADER -->
				<v-row>
					<v-col>
						<Introduction
							subheading="app.projects_overview_desc"
						></Introduction>
					</v-col>
					<v-col sm="1">
						<!-- Notifications -->
						<div class="d-inline-block">
							<v-menu
								offset-y
								open-on-hover
								left
								:close-on-content-click="false"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-badge
										bordered
										color="error"
										overlap
										:content="newNotifications.length"
										:value="newNotifications.length"
									>
										<v-icon v-bind="attrs" v-on="on" color="wr_indigo_2">
											$vuetify.icons.values.bell
										</v-icon>
									</v-badge>
								</template>
								<v-card width="500">
									<Notifications></Notifications>
								</v-card>
							</v-menu>
						</div>
						<!-- Logout -->
						<div class="d-inline-block ms-8">
							<TheLogout :show_img="true"></TheLogout>
						</div>
					</v-col>
				</v-row>
			</v-card-text>
			<!-- Projects display -->
			<v-card-text :style="{ height: '80% !important' }">
				<Table></Table>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import Notifications from "@/components/shared/dashboard/Notifications.vue";
import Table from "@/components/shared/project/List.vue";
import TheLogout from "@/components/layout/TheLogout.vue";
import Introduction from "@/components/shared/dashboard/Introduction.vue";

export default {
	name: "Projects",

	components: {
		Notifications,
		Table,
		TheLogout,
		Introduction,
	},

	computed: {
		...mapState({
			inbox: (state) => state.notification.inbox,
		}),

		newNotifications() {
			return this.inbox.filter((item) => item.msg_new).slice(0, 3);
		},
	},
};
</script>
